import React, { useEffect, useRef } from 'react';
import s from './BgLine1.scss';

import gsap from 'gsap';
import DrawSVGPlugin from 'utils/DrawSVGPlugin.js';
gsap.registerPlugin(DrawSVGPlugin);

export const BgLine1 = () => {

  const lineRef = useRef(null);

  useEffect(() => {
    gsap.fromTo(
      lineRef.current,
      {
        drawSVG: '0%',
      },
      {
        duration: 2.5,
        delay: 2.5,
        drawSVG: '100%',
        ease: 'linear',
      }
    );
  }, [])

  return (
    <div id="hero__line" className={s.hero__line}>
      <svg xmlns="http://www.w3.org/2000/svg" width="2000" height="825" viewBox="0 0 2000 825" fill="none">
        <path 
          ref={lineRef}
          fill="none"
          strokeMiterlimit="10"
          d="M1,0l0,624c0,110.5,89.5,200,200,200h1799.2"/>
      </svg>
    </div>
  );
};
